import React, {useEffect, useState} from "react";
import Particles, {initParticlesEngine} from "@tsparticles/react";
import {loadFull} from "tsparticles";
import "./App.css";
import particlesOptions from "./particles.json";

function App() {
    const [init, setInit] = useState(false);

    useEffect(() => {
        if (init) {
            return;
        }

        initParticlesEngine(async (engine) => {
            await loadFull(engine);
        }).then(() => {
            setInit(true);
        });
    }, [init]);

    return (
        <div className="App">
            {init && <Particles options={particlesOptions}/>}
            <img src="avinash.png" alt="hello" style={{height: 200,width: 200, borderRadius: 500,margin: 'auto',marginTop: 200}}></img>
            <h1 style={{color: 'white',fontSize: 100}}>Avinash is a good boy</h1>
        </div>
    );
}

export default App;
